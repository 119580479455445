import { useEffect, useRef, useState } from 'react'
import {
  getCustomProfile,
  putCustomProfile,
} from '../../../api/CustomProfilesAPIService'
import MainNavMenu from '../../../menus/MainNavMenu/MainNavMenu'
import { useDispatch, useSelector } from 'react-redux'
import { updateContactDetails, updateCustomUnits } from '../../Users/UserSlice'
import {
  ISubscriptionInfo,
  UpdatePassword,
  cancelSubscription,
  fetchUserDetails,
  resubscribe,
  subscriptionInfo,
} from '../../../services/UserApiService'
import { RootState } from '../../../store'
import { toastType } from '../../Toast/Toast'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import ConfirmDialog from '../../ConfirmDialog'
import { TWS_API_URL, TWS_V15_URL } from '../../../environment/apis.config'
import { getUser } from '../../../utils/auth.util'
import { useAuth0 } from '@auth0/auth0-react'

function updateCustomProfiles(
  tempUnit: string,
  windSpeedUnit: string,
  precipRateUnit: string
) {
  const payload = {
    temperature: tempUnit,
    wind_speed: windSpeedUnit,
    precipitationRate: precipRateUnit,
  }

  return putCustomProfile(payload)
}

function validateString(s: string): boolean {
  // Check the length of the string
  if (s.length < 8) {
    return false
  }

  // Check for at least 1 uppercase letter
  if (!/[A-Z]/.test(s)) {
    return false
  }

  // Check for at least 1 lowercase letter
  if (!/[a-z]/.test(s)) {
    return false
  }

  // Check for at least 1 special character
  if (!/[\W_]/.test(s)) {
    return false
  }

  // Check for at least 1 number
  if (!/[0-9]/.test(s)) {
    return false
  }

  return true
}

function UserProfile() {
  const [isLoading, setIsLoading] = useState(true)
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [reseller, setReseller] = useState('')
  const [userMobile, setUserMobile] = useState('')
  const dispatch = useDispatch()
  const clientid = useSelector((state: RootState) => state.user.id)
  const [editUserSettings, setEditUserSettings] = useState(false)
  const errorStatus = useErrorStatus()
  const [isConfirmSubscriptionDlgOpen, setIsConfirmSubscriptionDlgOpen] =
    useState(false)
  const [showTrialEndMsg, setShowTrialEndMsg] = useState(false)

  const oldPasswordRef = useRef<HTMLInputElement>(null)
  const newPasswordRef = useRef<HTMLInputElement>(null)
  const confirmNewPasswordRef = useRef<HTMLInputElement>(null)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastType] = useState(toastType.info)
  const [newPwdError, setNewPwdError] = useState(false)
  const [oldPwdError, setOldPwdError] = useState(false)
  const [confirmNewPwdError, setConfirmNewPwdError] = useState(false)
  const today = new Date()
  const [expirationDate, setExpirationDate] = useState(today)
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const [cancelAt, setCancelAt] = useState<string | null>(null)
  const [isActive, setIsActive] = useState(true)
  const [showResubConfirmDlg, setShowResubConfirmDlg] = useState(false)
  const subscriptionDetails: ISubscriptionInfo = {
    productName: null,
    price: null,
    date: null,
    period: null,
    trial_active: null,
    cancel_at_period_end: null,
    isActive: null,
    cancelAt: null,
  }

  // const userId = sessionStorage.getItem('userId')

  // const userId = localStorage.getItem('userId')
  
  const { isAuthenticated } = useAuth0();
  const [userId, setUserId] = useState(getUser()?.sub);
  const user = useSelector((state: RootState) => state.user)
  const statusError = useErrorStatus()
  const customUnits = useSelector((state: RootState) => state.user.customUnits)
  const productnameRef = useRef<HTMLInputElement>(null)
  const [notActive, setNotActive] = useState<boolean>(false)
  const priceRef = useRef<HTMLInputElement>(null)
  const dateRef = useRef<HTMLInputElement>(null)
  const periodRef = useRef<HTMLInputElement>(null)
  const trial_activeRef = useRef<HTMLInputElement>(null)
  const [cancel_at_period_end, setCancel_at_period_end] =
    useState<boolean>(false)
  const [temperatureUnits, setTemperatureUnits] = useState('')
  const [windSpeedUnits, setWindSpeedUnits] = useState('')
  const [precipRateUnits, setPrecipRateUnits] = useState('')

  //fetches subscription info and custom profile details
  const fetchApiDetails = () => {
    const customprofile = getCustomProfile(true)
    customprofile
      .then((res) => {
        const units = res?.customProfile?.units
        if (units && units.temperature) setTemperatureUnits(units?.temperature)
        if (units && units.wind_speed) setWindSpeedUnits(units?.wind_speed)
        if (units && units.precipitationRate)
          setPrecipRateUnits(units?.precipitationRate)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    if (editUserSettings) {
      statusError.addMessage(
        'User contact details updated successfully',
        200,
        'success',
        true
      )
      setEditUserSettings(false)
    }
    subscriptionInfo()
      .then((res) => {
        if (res !== undefined) {
          subscriptionDetails.productName = res.productName
          subscriptionDetails.price = res.price
          subscriptionDetails.date = res.date
          subscriptionDetails.period = res.period
          subscriptionDetails.trial_active = res.trial_active
          subscriptionDetails.isActive = res.isActive
          subscriptionDetails.cancel_at_period_end = res.cancel_at_period_end

          setCancelAt(res.cancelAt)
          setIsActive(
            subscriptionDetails.isActive !== null
              ? subscriptionDetails.isActive
              : false
          )
          setCancel_at_period_end(
            subscriptionDetails.cancel_at_period_end !== null
              ? subscriptionDetails.cancel_at_period_end
              : false
          )
          if (!subscriptionDetails.trial_active && isExpired) {
            setShowTrialEndMsg(true)
          }
          if (trial_activeRef.current) {
            trial_activeRef.current.value =
              res.trial_active !== null ? res.trial_active.toString() : 'false'
          }
          if (priceRef.current) {
            priceRef.current.value =
              res.price !== null ? res.price.toString() : '0.00'
          }
          if (dateRef.current) {
            const dt = new Date(res.date !== null ? res.date.toString() : today)
            dateRef.current.value = dt.toLocaleDateString()
            setExpirationDate(dt)
            if (dt < today) {
              setIsExpired(true)
            } else {
              setIsExpired(false)
            }
          }
          if (periodRef.current) {
            periodRef.current.value =
              res.period !== null ? res.period.toString() : 'none'
          }
          if (productnameRef.current) {
            productnameRef.current.value =
              res.productName !== null ? res.productName : 'none'
          }
        }
      })
      .catch((error) => {
        setIsActive(false)
        statusError.addMessage(
          "Currently you don't have an active subscription.",
          400,
          'error',
          false
        )
      })
  }

  useEffect(() => {
    if (isAuthenticated && getUser()) {
      setUserId(getUser()?.sub);
      fetchApiDetails();
    }
  }, [isAuthenticated, getUser()]);

  useEffect(() => {
    if (user) {
      setUserFirstName(user.firstname !== null ? user.firstname : '')
      setUserLastName(user.lastname !== null ? user.lastname : '')
      setUserEmail(user.email !== null ? user.email : '')
      setUserMobile(user.primary !== null ? user.primary : '')
      setReseller(user.reseller !== null ? user.reseller.reseller : '')
    }
  }, [user])

  useEffect(() => {
    if (newPasswordRef.current !== null) {
      // adding the new-password attribute to the password
      // this doesn't seem to work for me, but the council of wise elders say to do it.
      newPasswordRef.current.setAttribute('autocomplete', 'new-password')
    }
    if (confirmNewPasswordRef.current !== null) {
      confirmNewPasswordRef.current.setAttribute('autocomplete', 'new-password')
    }
    if (oldPasswordRef.current !== null) {
      oldPasswordRef.current.setAttribute('autocomplete', 'new-password')
    }
  }, [])

  const handleCancelSubButton = () => {
    if (subscriptionDetails && !subscriptionDetails.trial_active) {
      setIsConfirmSubscriptionDlgOpen(true)
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.setItem('logout-event', 'logout' + Math.random())
    setNotActive(true)
    setTimeout(() => {
      window.location.href = TWS_V15_URL
    }, 5000)
  }

  const CancelSubscription = () => {
    const result = cancelSubscription()
    result
      .then((res) => {
        setIsConfirmSubscriptionDlgOpen(false)
        setCancel_at_period_end(true)
        statusError.addMessage(
          'We’re sorry to see you go.  Please continue to visit TruWeatherSolutions.com to keep up with our latest innovations.',
          200,
          'success',
          true,
          30000
        )
      })
      .catch((err) => {
        statusError.addMessage(
          'Problem cancelling subscription.',
          200,
          'error',
          false,
          30000
        )
      })
  }

  const handleResubscribeButton = () => {
    setShowResubConfirmDlg(true)
  }

  const doResubscribe = () => {
    const result = resubscribe()
    result
      .then((res) => {
        setShowResubConfirmDlg(false)
        statusError.addMessage(
          'Your subscription has been re-enabled.',
          200,
          'success',
          true,
          30000
        )
        setCancel_at_period_end(true)
      })
      .catch((err) => {
        statusError.addMessage(
          'There was a problem resubscribing.',
          200,
          'error',
          false,
          30000
        )
      })
  }

  const handleChangePassword = () => {
    const oldPwd =
      oldPasswordRef.current !== null ? oldPasswordRef.current.value : ''
    const newPwd =
      newPasswordRef.current !== null ? newPasswordRef.current.value : ''
    const confirmNewPwd =
      confirmNewPasswordRef.current !== null
        ? confirmNewPasswordRef.current.value
        : ''

    if (oldPwd === '' || newPwd === '' || confirmNewPwd === '') {
      const msg =
        oldPwd === ''
          ? 'Please enter your old password'
          : newPwd === ''
          ? 'Please enter your new password'
          : 'Please enter your confirm new password'

      setToastMessage(msg)
      errorStatus.addMessage(msg, 200, toastType.error, true)
    } else {
      if (!validateString(newPwd) || !validateString(confirmNewPwd)) {
        errorStatus.addMessage(
          'New password must contain Minimum eight characters at least one uppercase letter, one lowercase letter, one special character.',
          200,
          toastType.error,
          true
        )
        setNewPwdError(true)
        setTimeout(() => {
          setNewPwdError(false)
        }, 7000)
      } else {
        if (
          oldPasswordRef.current !== null &&
          newPasswordRef.current !== null &&
          confirmNewPasswordRef.current !== null
        ) {
          if (
            newPasswordRef.current.value === confirmNewPasswordRef.current.value
          ) {
            UpdatePassword({
              clientId: clientid,
              oldPassword: oldPasswordRef.current.value,
              newPassword: newPasswordRef.current.value,
            })
              .then((response) => {
                if (response.message === 'Password successfully updated.') {
                  newPasswordRef.current !== null
                    ? (newPasswordRef.current.value = '')
                    : null
                  oldPasswordRef.current !== null
                    ? (oldPasswordRef.current.value = '')
                    : null
                  confirmNewPasswordRef.current !== null
                    ? (confirmNewPasswordRef.current.value = '')
                    : null

                  errorStatus.addMessage(
                    response.message,
                    200,
                    toastType.success,
                    false
                  )
                } else {
                  errorStatus.addMessage(
                    'Old password is incorrect',
                    200,
                    toastType.error,
                    true
                  )
                }
              })
              .catch((error) => {
                errorStatus.addMessage(
                  'Error updating password',
                  200,
                  toastType.error,
                  true
                )
              })
          } else {
            errorStatus.addMessage(
              'New passwords do not match.',
              200,
              toastType.error,
              true
            )
            setConfirmNewPwdError(true)
            setTimeout(() => {
              setConfirmNewPwdError(false)
            }, 7000)
          }
        }
      }
    }
  }

  const handleUpdateProfile = () => {
    const putProfile = putCustomProfile({
      temperature: temperatureUnits,
      wind_speed: windSpeedUnits,
      precipitationRate: precipRateUnits,
    })
    dispatch(
      updateCustomUnits({
        temperature: temperatureUnits,
        wind_speed: windSpeedUnits,
        precipitationRate: precipRateUnits,
      })
    )

    errorStatus.addMessage(
      'Custom Units for your profile have been updated',
      200,
      toastType.success,
      true
    )
  }

  const editUser = () => {
    dispatch(
      updateContactDetails({
        userId: userId,
        firstname: userFirstName,
        lastname: userLastName,
        // email: userEmail,
        primary: userMobile,
      })
    )
  }

  return (
    <div className="max-h-screen flex flex-col w-full  overflow-y-auto">
      <ConfirmDialog
        isOpen={isConfirmSubscriptionDlgOpen}
        onConfirm={CancelSubscription}
        onCancel={() => {
          setIsConfirmSubscriptionDlgOpen(false)
        }}
        message="Are you sure you want to cancel your subscription?"
      />
      <ConfirmDialog
        isOpen={showResubConfirmDlg}
        onConfirm={doResubscribe}
        onCancel={() => {
          setShowResubConfirmDlg(false)
        }}
        message="Are you sure you want to resubscribe?"
      />

      <MainNavMenu />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full h-full bg-white p-4 px-12 lg:px-4 xl:px-16 text-sm">
        <div className="flex flex-col bg-gray-100 p-4 rounded-2xl">
          <h3 className="text-2xl text-left font-light">User Settings</h3>
          <div className="grid grid-cols-2 gap-2  ">
            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                First Name <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                    !editUserSettings
                      ? 'bg-white'
                      : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                  }`}
                  value={userFirstName}
                  disabled={!editUserSettings}
                  onChange={(e) => setUserFirstName(e.target.value)}
                />
              </div>
            </div>

            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                Last Name <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                    !editUserSettings
                      ? 'bg-white'
                      : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                  }`}
                  value={userLastName}
                  disabled={!editUserSettings}
                  onChange={(e) => setUserLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                Email <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                    !editUserSettings
                      ? 'bg-white'
                      : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                  }`}
                  value={userEmail}
                  disabled={true}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                Mobile <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                    !editUserSettings
                      ? 'bg-white'
                      : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                  }`}
                  value={userMobile}
                  disabled={!editUserSettings}
                  onChange={(e) => setUserMobile(e.target.value)}
                />
              </div>
            </div>

            <div className="text-left mt-[-0.5rem]">
              {editUserSettings ? (
                <div className="flex ">
                  <div className="py-2.5 mr-12 mt-4 text-center text-white bg-green-500 rounded-md w-24 h-10">
                    <button
                      onClick={() => {
                        editUser()
                      }}
                    >
                      SAVE
                    </button>
                  </div>
                  <div className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-24 h-10">
                    <button
                      onClick={() => {
                        setEditUserSettings(false)
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              ) : (
                <div className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-24 h-10">
                  <button
                    onClick={() => {
                      setEditUserSettings(true)
                    }}
                  >
                    EDIT
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-gray-100 p-4 rounded-2xl">
          <h3 className="text-2xl text-left font-light">Change Password</h3>
          <div className="grid grid-cols-2 gap-2  ">
            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                Old Password <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  ref={oldPasswordRef}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
               dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="password"
                  name="oldpw"
                  style={oldPwdError ? { border: '1px solid red' } : {}}
                />
              </div>
            </div>

            <div className="">
              <div className="text-left"></div>
            </div>

            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                New Password <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  ref={newPasswordRef}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
              dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="password"
                  name="newpw"
                  style={newPwdError ? { border: '1px solid red' } : {}}
                />
              </div>
            </div>

            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                Confirm New Password <span className="text-red-600">*</span>
              </div>
              <div className="text-left">
                <input
                  ref={confirmNewPasswordRef}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
              dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="password"
                  name="confirmpw"
                  style={confirmNewPwdError ? { border: '1px solid red' } : {}}
                />
              </div>
            </div>

            <div className="text-left mt-[-0.5rem]">
              <button
                onClick={handleChangePassword}
                className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-40 h-10"
              >
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-gray-100 p-4 rounded-2xl">
          <h3 className="text-2xl text-left font-light">Custom Units</h3>
          <div className="grid grid-cols-3 gap-2  ">
            <div className="">
              <div className="text-left text-sm mt-2 mb-1">Temperature</div>
              <div className="text-left">
                <select
                  value={temperatureUnits}
                  onChange={(e) => setTemperatureUnits(e.target.value)}
                  className="w-full border border-gray-300 
                  text-gray-900 text-m rounded-lg block p-1.5 
                  bg-white dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400"
                >
                  <option value="C">&#176; C</option>
                  <option value="F">&#176; F</option>
                </select>
              </div>
            </div>

            <div className="">
              <div className="text-left text-sm mt-2 mb-1">Wind Speed</div>
              <div className="text-left">
                <select
                  value={windSpeedUnits}
                  onChange={(e) => setWindSpeedUnits(e.target.value)}
                  className="w-full border border-gray-300 
                  text-gray-900 text-m rounded-lg block p-1.5 
                  bg-white dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400"
                >
                  <option value="KTS">KTS</option>
                  <option value="MPH">MPH</option>
                  <option value="MPS">MPS</option>
                </select>
              </div>
            </div>

            <div className="">
              <div className="text-left text-sm mt-2 mb-1">
                Precipitation Rate
              </div>
              <div className="text-left">
                <select
                  value={precipRateUnits}
                  onChange={(e) => setPrecipRateUnits(e.target.value)}
                  className="w-full border border-gray-300 
                    text-gray-900 text-m rounded-lg block p-1.5 
                    bg-white dark:bg-gray-700 dark:border-gray-600 
                    dark:placeholder-gray-400"
                >
                  <option value="MM/HR">MM / HR</option>
                  <option value="IN/HR">IN / HR</option>
                </select>
              </div>
            </div>

            <div className="text-left mt-[-0.5rem]">
              <button
                type="button"
                className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-40 h-10"
                onClick={handleUpdateProfile}
              >
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>

        {(reseller == '' || reseller == 'default') && (
          <div className="flex flex-col bg-gray-100 text-left p-4 rounded-2xl">
            <h3 className="text-2xl text-left font-light mb-4">
              Subscription Details
            </h3>
            <div className="grid grid-cols-2 gap-2  ">
              {showTrialEndMsg && (
                <div className="col-span-2">
                  <strong>
                    Your TruWeather Solutions V360 Trial Has Concluded - Keep
                    Your Missions Flying High!
                  </strong>
                  <p className="my-3">
                    It looks like your trial period with TruWeather Solutions
                    V360 has ended. We hope you've seen clear skies ahead with
                    our precision meteorological insights.
                  </p>
                  <p>
                    Don’t let unpredictable weather ground your missions. By
                    upgrading to a full subscription, ensure continuous access
                    to the real-time data and forecasts necessary for safe,
                    efficient flights.
                  </p>
                  <button
                    type="button"
                    className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-56 h-10"
                    onClick={() => {
                      window.location.href = `${TWS_API_URL}/payment?token=${localStorage.getItem(
                        'token'
                      )}`
                    }}
                  >
                    SEE SUBSCRIPTION OPTIONS
                  </button>
                </div>
              )}
              {!showTrialEndMsg && !isActive && (
                <div className="col-span-2">
                  <strong>
                    Your TruWeather Solutions V360 Subscription{' '}
                    {isExpired ? 'has ended ' : 'is not active '} - Keep Your
                    Missions Flying High!
                  </strong>
                  <p className="my-3">
                    It looks like your subscription with TruWeather Solutions
                    V360 {isExpired ? 'has ended' : 'is not active'}. We hope
                    you've seen clear skies ahead with our precision
                    meteorological insights.
                  </p>
                  <p>
                    Don’t let unpredictable weather ground your missions.{' '}
                    {isExpired
                      ? 'By resubscribing, '
                      : 'By purchasing a subscription, '}{' '}
                    ensure continuous access to the real-time data and forecasts
                    necessary for safe, efficient flights.
                  </p>
                  <button
                    type="button"
                    className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-56 h-10"
                    onClick={() => {
                      window.location.href = `${TWS_API_URL}/payment?token=${localStorage.getItem(
                        'token'
                      )}`
                    }}
                  >
                    SEE SUBSCRIPTION OPTIONS
                  </button>
                </div>
              )}
              {!showTrialEndMsg && isActive && (
                <>
                  <div className="text-left">
                    <div className="text-left text-sm mt-2 mb-1">
                      Product Name
                    </div>
                    <input
                      type="text"
                      disabled
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                      ref={productnameRef}
                    />
                  </div>
                  <div className="text-left">
                    <div className="text-left text-sm mt-2 mb-1">Price</div>
                    <input
                      type="text"
                      disabled
                      ref={priceRef}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                    />
                  </div>
                  <div className="text-left">
                    <div className="text-left text-sm mt-2 mb-1">
                      Expiration Date
                    </div>
                    <input
                      type="text"
                      disabled
                      ref={dateRef}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                    />
                  </div>
                  <div className="text-left">
                    <div className="text-left text-sm mt-2 mb-1">On Trial</div>
                    <input
                      type="text"
                      disabled
                      ref={trial_activeRef}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                    />
                  </div>
                  <div className="text-left">
                    <div className="text-left text-sm mt-2 mb-1">
                      Subscription Period
                    </div>
                    <input
                      type="text"
                      disabled
                      ref={periodRef}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                    />
                  </div>
                  <div className="text-left"></div>
                  <div className="text-left mt-[-0.5rem]">
                    {!cancel_at_period_end ? (
                      <button
                        onClick={handleCancelSubButton}
                        type="button"
                        className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md h-10 px-4"
                      >
                        CANCEL SUBSCRIPTION
                      </button>
                    ) : cancel_at_period_end ? (
                      <button
                        onClick={handleResubscribeButton}
                        type="button"
                        className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md h-10 px-4"
                      >
                        RESUBSCRIBE
                      </button>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserProfile
