import { TWS_API_URL } from '../../environment/apis.config'
import { AIRMETAPIParser } from '../../utils/AIRMETUtils'
import { getAccessToken } from '../../utils/auth.util';
import { timeouts } from '../RequestTimeouts'

export const fetchAIRMETData = async (
  defaultLocation: { latitude: any; longitude: any } | undefined
) => {
  const controller = new AbortController()
  return new Promise(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      clearTimeout(timeoutId)
      reject(new Error('AIRMET request timeout'))
      // store.dispatch(hideApiLoading())
    }, timeouts.airmet)
    const token = await getAccessToken();
    fetch(
      `${TWS_API_URL}/airmet?lat=${defaultLocation.latitude}&long=${defaultLocation?.longitude}`,
      {
        method: 'GET',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
      }
    )
      .then((res) => {
        clearTimeout(timeoutId)
        if (!res.ok) {
          throw new Error('Failed to fetch AIRMET data')
        }
        return res.json()
      })
      .then((data) => {
        resolve(AIRMETAPIParser(data))
      })
      .catch((error) => {
        clearTimeout(timeoutId)
        if (error.name === 'AbortError') {
          reject(new Error('AIRMET request timeout.'))
        } else {
          reject(error)
        }
      })
  })
}

export default fetchAIRMETData
