import { TWS_API_URL } from '../../environment/apis.config'
import { Payload } from './MissionCASTSlice'
import { timeouts } from '../../services/RequestTimeouts'
import { store } from '../../store'
import { showApiLoading, hideApiLoading } from '../Notifier/StatusQueueSlice'
import { getAccessToken } from '../../utils/auth.util'

const fetchMissionCASTData = (payload: Payload): Promise<string | any> => {

  // const token = sessionStorage.getItem('token')
  // const token = localStorage.getItem('token')
  const controller = new AbortController()
  const signal = controller.signal

  return new Promise(async (resolve, reject) => {
    // store.dispatch(showApiLoading())
    const timeoutId = setTimeout(() => {
      controller.abort()
      clearTimeout(timeoutId)
      // reject(new Error('Request Timeout'))
      // store.dispatch(hideApiLoading())
    }, timeouts.missionCAST)
    const token = await getAccessToken();
    fetch(`${TWS_API_URL}/missioncast`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        waypoints: [payload],
        subset: [
          'Pressure',
          'Ceiling',
          'DewpointTemperature',
          'Temperature',
          'Visibility',
          'WindDirection',
          'WindSpeed',
          'Weather',
          'PrecipitationPotentialIndex',
          'WindGust',
        ],
        forecastRange: 48,
        format: 'HTMLString',
        thresholds: {},
      }),
      signal,
    })
    .then((response) => {
      clearTimeout(timeoutId);
      if (response.status === 200) {
        resolve(response.json());
      } else {
        // Reject the promise with a custom error for non-200 responses
        reject({
          status: 400,
          message: "Something went wrong in missioncast. Please Try again",
        });
      }
      })
      .catch((err) => {
        clearTimeout(timeoutId)
        if (err.name === 'AbortError') {
          reject(new Error('Request timed out. Please try again.'))
        }
        reject(err)
      })
  })
}

export default fetchMissionCASTData
