import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TWS_API_URL } from '../../../environment/apis.config'
import { ALERTEDITAPIParser } from '../../../utils/ALERTUtils'
import { setDisabledMenuItem } from '../../../menus/SideNavigation/SideNavigationSlice'
import store from '../../../store'
import { getAccessToken } from '../../../utils/auth.util'

const API_ENDPOINT = TWS_API_URL

const initialState = {
  showAlertSETTING: false,
  setLocation: '',
  setLocationType: '',
  setNotificationAlert: '',
  setDropDownAlert: false,
  showAlertSETTINGEdit: false,
  showAlertSETTINGCreate: false,
  editData: '',
  editeventData: '',
  showAlert: false,
  hideAlertSETTINGEdit: true,
  hideAlertSETTING: true,
  setProperties: '',
  setAlertingNotification: [],
  removeAlert: [],
  removeAlertForParticularHazard: [],
  callNetworkAdapter: false,
  sideNavAlerts: [],
  callSpecificAlert: false,
  responseOk: { valid: false },
  showAlertNotification: [],
  showEventList: false,
  storingEventsToDisplay: {},
  finalEventsToDisplay: {},
  showEventsIfAlertNotPresent: false,
  isPolygon: false,
  outOfBounds: false,
  eventCount: 0,
}

export const addUpdateEvent = async (updatedEventLocalData, editable) => {
  const method = editable ? 'PUT' : 'POST'
  const url = `${API_ENDPOINT}/events`
  const token = await getAccessToken();
    const requestOptions = {
    method: method,
    headers: {
      // Authorization: `Bearer ` + sessionStorage.getItem('token'),
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedEventLocalData),
  }

  try {
    const response = await fetch(url, requestOptions)
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const getAllEvents = async () => {
  try {
    const token = await getAccessToken();
    const response = await fetch(`${TWS_API_URL}/events/user`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ` + sessionStorage.getItem('token'),
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      if (response.status == 400) {
        store.dispatch(
          setDisabledMenuItem({ id: 'alerts-menu', disabled: true })
        )
        store.dispatch(
          setDisabledMenuItem({ id: 'decision-routcase', disabled: true })
        )
      }
      return []
    }
    store.dispatch(setDisabledMenuItem({ id: 'alerts-menu', disabled: false }))
    store.dispatch(
      setDisabledMenuItem({ id: 'decision-routcase', disabled: false })
    )
    const data = await response.json()
    return ALERTEDITAPIParser(data)
  } catch (error) {
    console.log(error)
    return error
  }
}

const AlertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<string>) => {
      state.setLocation = action.payload
    },
    showAlert: (state, action) => {
      state.showAlert = action.payload
    },
    sendNotificationAlert(state, action: PayloadAction<string>) {
      state.setNotificationAlert = action.payload
    },
    editData(state, action) {
      state.editData = action.payload
    },
    showAlertSETTING(state) {
      state.showAlertSETTING = true
    },
    showAlertSETTINGEdit(state) {
      state.showAlertSETTINGEdit = true
    },
    hideAlertSETTINGEdit(state) {
      state.showAlertSETTINGEdit = false
    },
    hideAlertSETTING(state) {
      state.showAlertSETTING = false
    },
    setLocationType: (state, action: PayloadAction<string>) => {
      state.setLocationType = action.payload
    },
    setDropDownAlert: (state, action) => {
      state.setDropDownAlert = action.payload
    },
    responseOk: (state, action) => {
      state.responseOk = action.payload
    },
    setEventEdit(state, action) {
      state.editeventData = action.payload
    },
    setProperties(state, action) {
      state.setProperties = action.payload
    },
    setAlertingNotification(state, action) {
      state.setAlertingNotification = action.payload
    },
    callNetworkAdapter(state, action) {
      state.callNetworkAdapter = action.payload
    },
    callSpecificAlert(state, action) {
      state.callSpecificAlert = action.payload
    },
    removeAlert(state, action) {
      state.removeAlert.push(action.payload)
    },
    removeAlertForParticularHazard(state, action) {
      state.removeAlertForParticularHazard.push(action.payload)
    },
    sideNavAlerts(state, action) {
      //state.sideNavAlerts = []
      const index = state.sideNavAlerts.indexOf(action.payload)
      if (index != -1) {
        state.sideNavAlerts.splice(index, 1)
      } else {
        state.sideNavAlerts.push(action.payload)
      }
      state.removeAlertForParticularHazard = []
    },
    sideNavRefresh(state) {
      state.callSpecificAlert = false
      state.sideNavAlerts = []
    },
    showAlertSETTINGCreate(state, action) {
      state.showAlertSETTINGCreate = action.payload
    },
    showAlertNotification(state, action) {
      state.showAlertNotification = []
      state.showAlertNotification.push(action.payload)
    },
    showEventList(state, action) {
      state.showEventList = action.payload
    },
    storingEventsToDisplay(state, action) {
      state.storingEventsToDisplay = action.payload
    },
    finalEventsToDisplay(state, action) {
      state.finalEventsToDisplay = action.payload
    },
    showEventsIfAlertNotPresent(state, action) {
      state.showEventsIfAlertNotPresent = action.payload
    },
    toggleAlert: (state, action: PayloadAction<boolean>) => {
      state.showAlert = action.payload
    },
    isPolygon(state, action) {
      state.isPolygon = action.payload
    },
    outOfBounds(state, action) {
      state.outOfBounds = action.payload
    },
    setEventCount(state, action) {
      state.eventCount = action.payload
    },
    outOfBounds(state, action) {
      state.outOfBounds = action.payload
    },
  },
})
export const {
  setLocationType,
  setLocation,
  showAlertSETTING,
  hideAlertSETTING,
  sendNotificationAlert,
  setDropDownAlert,
  hideAlertSETTINGEdit,
  showAlertSETTINGEdit,
  editData,
  setEventEdit,
  showAlert,
  setProperties,
  //  setAlertingNotification,removeAlert,callNetworkAdapter,sideNavAlerts,callSpecificAlert,sideNavRefresh,responseOk, storingEventsToDisplay, finalEventsToDisplay, showAlertNotification, showEventsIfAlertNotPresent, showAlertSETTINGCreate, showEventList, } = AlertSlice.actions
  setAlertingNotification,
  removeAlert,
  callNetworkAdapter,
  sideNavAlerts,
  callSpecificAlert,
  sideNavRefresh,
  responseOk,
  storingEventsToDisplay,
  finalEventsToDisplay,
  showAlertNotification,
  showEventsIfAlertNotPresent,
  showAlertSETTINGCreate,
  showEventList,
  isPolygon,
  removeAlertForParticularHazard,
  toggleAlert,
  outOfBounds,
  setEventCount,
} = AlertSlice.actions

export default AlertSlice.reducer
