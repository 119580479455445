import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0User, setTokenGetter, setUser } from "./auth.util";
import { TWS_API_URL } from '../environment/apis.config';

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { 
    isAuthenticated, 
    isLoading, 
    error, 
    user, 
    loginWithRedirect, 
    getAccessTokenSilently, 
    logout
  } = useAuth0();
  const [isUserActive, setIsUserActive] = useState(false);

  useEffect(() => {
    const redirectToPayment = async () => {
      const token = await getAccessTokenSilently();
      const url = `${TWS_API_URL}/payment?token=${token}`;
      window.location.href = url;
    };

    if (isLoading) return;

    if (error) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
    } else if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      loginWithRedirect();
    } else if (isAuthenticated && user) {
      // Set auth.util helpers
      setTokenGetter(() => getAccessTokenSilently());
      setUser(user as Auth0User);
      
      const isActive = user.isActive;
      if (!isActive) {
        redirectToPayment();
      }
      setIsUserActive(isActive);
    }
  }, [isAuthenticated, error, isLoading, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated && isUserActive) {
    return <>{children}</>;
  }

  // Render nothing while redirecting or handling errors
  return null;
};

export default RequireAuth;
