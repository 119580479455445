import { useEffect, useState } from 'react'
import chevronLeft from '../../assets/chevron-left.svg'
import chevronRight from '../../assets/chevron-right.svg'
import { TWS_API_URL } from '../../environment/apis.config'
import Trash from '../../assets/icons/bin.svg'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectedItems,
  deselectMenuItemById,
} from '../../menus/SideNavigation/SideNavigationSlice'
import {
  setEventCount,
  sideNavRefresh,
} from '../../Components/Alerting/AlertingSlice/AlertingSlice'
import store from '../../store'
import { getAccessToken } from '../../utils/auth.util'
const url = TWS_API_URL

// Get all the events
export async function getAllEvents() {
  try {
      const token = await getAccessToken();
      const response = await fetch(url + '/events/user', {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ` + sessionStorage.getItem('token'),
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    if (data.message == 'Payment Not Authorized.') return []
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

const SlideOverDialogEvents = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [eventsList, setEventData] = useState([])
  const [dataFetched, setFlag] = useState(false)

  const handleButtonClick = () => {
    setIsVisible(!isVisible)
  }
  const dispatch = useDispatch()

  const eventCount = useSelector((state: RootState) => state.alert.eventCount)

  const deleteEvent = async (index: any) => {
    if (confirm('Delete this Event?')) {
      const eventName = eventsList[index]['eventName']
      setEventData(eventsList.filter((event, i) => i !== index))
      const token = await getAccessToken();
      const response = await fetch(url + '/events', {
        method: 'DELETE',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          events: [eventName],
        }),
      })
      const data = await response.json()
      dispatch(selectedItems('Events List'))
      dispatch(deselectMenuItemById('events-list'))
      dispatch(selectedItems('Alerts'))
      dispatch(selectedItems('Alerts'))
      dispatch(setEventCount(eventCount - 1))
      dispatch(sideNavRefresh())
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!store.getState().sideNav.menuItems[0].subMenu[1].disabled) {
        const data = await getAllEvents()
        setEventData(data)
        setFlag(true)
      }
    }
    fetchData()
      // make sure to catch any error
      .catch(console.error)
  }, [])

  return (
    <div className="relative overflow-x-auto rounded text-sm bg-white w-full h-full">
      <table className="text-left">
        <thead className="uppercase bg-gray-400">
          <tr>
            <th scope="col" className="p-1">
              Event
            </th>
            <th scope="col" className="p-1">
              Start Time
            </th>
            <th scope="col" className="p-1">
              End Time
            </th>
            <th scope="col" className="p-1"></th>
          </tr>
        </thead>
        <tbody>
          {dataFetched && eventsList.length > 0 ? (
            eventsList.map((event, index) => (
              <tr className="bg-white border-b" key={event['eventID']}>
                <td scope="row" className="p-1 border border-slate-600">
                  {event['eventName']}
                </td>
                <td className="p-1 border border-slate-600">
                  {moment
                    .utc(event['startTime'])
                    .tz(moment.tz.guess())
                    .format('MMM D YYYY, HH:mm:ss')}
                </td>
                <td className="p-1 border border-slate-600">
                  {moment
                    .utc(event['endTime'])
                    .tz(moment.tz.guess())
                    .format('MMM D YYYY, HH:mm:ss')}
                </td>
                <td className="p-1 border border-slate-600">
                  <button
                    onClick={() => deleteEvent(index)}
                    className="block w-6 h-6"
                  >
                    {' '}
                    <img src={Trash} alt="" />{' '}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white border-b">
              <td scope="row" className="p-3 border border-slate-600">
                <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
              </td>
              <td className="p-3 border border-slate-600">
                <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
              </td>
              <td className="p-3 border border-slate-600">
                <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
              </td>
              <td className="p-3 border border-slate-600">
                <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {dataFetched && eventsList.length == 0 && (
        <div className="p-4 my-2 text-sm text-blue-800 rounded-lg bg-blue-50">
          No active events present at this time !!
        </div>
      )}
    </div>
  )
}

export default SlideOverDialogEvents
