export interface Auth0User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  org_id: string;
  picture: string;
  sub: string;
  updated_at: string;
  isActive: boolean;
  subscriptionId: string;
  freetrial: boolean;
}

let getToken: (() => Promise<string>) | null = null;
let user: Auth0User | null = null;

export const setTokenGetter = (getter: () => Promise<string>) => {
  getToken = getter;
};

export const setUser = (profile:Auth0User):void => {
  console.log(profile);
  user = profile;
}

export const getAccessToken = async (): Promise<string> => {
  if (!getToken) {
    throw new Error("Token getter is not set");
  }
  return await getToken();
};

export const getUser = ():Auth0User|null => {
  return user;
}
