import { PIREPAPIParser } from '../../utils/PIREPUtils'
import { TWS_API_URL } from '../../environment/apis.config'
import { timeouts } from '../RequestTimeouts'
import { getAccessToken } from '../../utils/auth.util';


export const fetchPIREPData =  async (defaultLocation: { latitude: any; longitude: any; } | undefined) => {
  const distance = 100
  const controller = new AbortController()

  return new Promise(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('PIREP request timed out. Please try again.'))
    }, timeouts.pirep)
    const token = await getAccessToken();

      const response = await fetch(
        `${TWS_API_URL}/pirep?lat=${defaultLocation.latitude}&long=${defaultLocation.longitude}&distance=${distance}`,
          {
              method: 'GET',
              headers: {
                  // Authorization: `Bearer ` + sessionStorage.getItem('token'),
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              signal: controller.signal,
          }
      )
      .then(res => {
         clearTimeout(timeoutId)
         if (!res.ok) {
             throw new Error("Failed to fetch PIREP data")
         }
         return res.json()
      })
      .then(data => {
        resolve(PIREPAPIParser(data))
      })
      .catch(error => {
         if (error.name === 'AbortError') {
             reject(new Error('PIREP request aborted.'))
         } else {
             reject(error)
         }
      })
  })
}

export default fetchPIREPData
