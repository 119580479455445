/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
//npm module imports
import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { RootState } from './store'
import { ReactElement, useState, useEffect, SyntheticEvent } from 'react'
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'

//component imports
import 'normalize.css'
import './App.css'
import {
  selectedItemIds,
  selectedItems,
  prependMenuItem,
  removeMenuItem,
  initSideMenu,
  setActiveMenuItem,
  disableAllMenuItems,
} from './menus/SideNavigation/SideNavigationSlice'
import SideNavigation from './menus/SideNavigation/SideNavigation'
import TopNavigation from './menus/TopNavigation/TopNavigation'
import LoginPage from './Components/Users/LoginPage/LoginPage'
import RegisterPage from './Components/Users/RegisterPage/RegisterPage'
import MapComponent from './map/MapComponent'
import ForgotPasswordPage from './Components/Users/ForgotPasswordPage/ForgotPasswordPage'
import UserProfile from './Components/Users/UserProfile/UserProfile'
import SlideOverDialog from './menus/SlideOverDialog/SlideOverDialog'
import TWSContext from './hooks/TWSMapContext'
import { SlideOverDialogProvider } from './menus/SlideOverDialog/SlideOverDialogContext'
import RequireAuth from './utils/RequireAuth'

import {
  setDefaultLocation,
  fetchUser,
  deleteUserLocation,
  userLogoutAction,
} from './Components/Users/UserSlice'
import MenuSelection from './Components/MenuSelection/MenuSelection'

import { Location } from './interfaces/Location'
import marker from './assets/marker.svg'
import SensorDetails from './Components/WeatherSensorDetails/SensorDetails'
import { IMenuItem } from './menus/SideNavigation/SideNavItems'
import icon from './assets/remove-menu-icon.svg'
import SlideOverDialogEvents from './menus/SlideOverDialog/SlideOverDialogEvents'
import useErrorStatus from './hooks/UseErrorStatus'
import FAQPage from './Components/FAQPage/FAQPage'
import { datadogRum } from '@datadog/browser-rum';

// Org IDs in Auth0
export const GRANDSKY_ORG_ID = "org_C14ErQWYGe0OjJde";
export const AEROX_ORG_ID = "org_fBQ1YDarZdeCSV6a";

datadogRum.init({
    applicationId: 'ed790640-74c7-409f-9ba0-6b1114397261',
    clientToken: 'pub5dd997545f36a70ab314711adaacc0d2',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'workbookv2',
    env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: ["https://test.truweathersolutions.com"]
});

// Start collecting RUM events
// datadogRum.startSessionReplayRecording();

interface IDeleteLocationProps {
  id: number | string
  menuIdString: string
}
const DeleteLocation = (props: IDeleteLocationProps) => {
  const errorStatus = useErrorStatus()
  const locations = useSelector((state: RootState) => state.user.locations)
  const dispatch = useDispatch()
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const clickHandler = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (locations?.length === 1) {
      alert('You need to have at least one location added to use the workbook.')
      return
    }
    if (confirm('Delete this location?')) {
      // sessionStorage.setItem('defaultLocation', locations[0].id)
      localStorage.setItem('defaultLocation', locations[0].id)
      const response = await dispatch(deleteUserLocation(props.id))
      if (response.payload !== null && !('error' in response)) {
        const defaultLoc = locations.filter((el) => el.id !== props.id)[0]
        dispatch(removeMenuItem(`locations-${props.menuIdString}`))
        dispatch(selectedItems(defaultLoc.name))
        dispatch(
          setActiveMenuItem({
            id: defaultLoc.name,
            active: true,
          })
        )
        // set any other location than the deleted location
        dispatch(setDefaultLocation(defaultLoc))
      } else {
        errorStatus.addMessage(
          'The location cannot be deleted. Please check your subscription and try again.',
          400,
          'error',
          true
        )
      }
    }
  }

  return (
    <div onClick={(e) => clickHandler(e)}>
      <img
        src={icon}
        alt="icon"
        className="h-5 mt-1 brightness-50 hover:brightness-100"
        title="Delete Location"
      />
    </div>
  )
}
import { CustomThresholds } from './Components/CustomThresholds/CustomThresholds'
import { SessionRedirect } from './Components/SessionRedirect/SessionRedirect'
import Notifier from './Components/Notifier/Notifier'
import { setSavedProfile } from './Components/SavedViews/savedViewsSlice'
import { fetchSavedProfiles } from './Components/SavedViews/savedViews'
import { useAuth0 } from '@auth0/auth0-react'

export const App = () => {
  const location = useLocation()
  const [sideNavExpanded, setSideNavExpanded] = useState(true)
  const [mapActive, setMapActive] = useState(false)
  const [selectedSideNav, setMessage] = useState('empty')
  const [numLocations, setNumLocations] = useState(0)
  const [twsElements, setTwsElements] = useState<ReactElement[]>([])
  const getDefaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const selectedItemsx = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const sideNavCallback = (isExpanded: boolean) => {
    setSideNavExpanded(isExpanded)
  }
  const { isAuthenticated } = useAuth0();

  // const userId = sessionStorage.getItem('userId')
  // const userId = localStorage.getItem('userId')
  // const token = sessionStorage.getItem('token')
  // const token = localStorage.getItem('token')
  const errorStatus = useErrorStatus()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const menuCallback = (extraData: any) => {
    return (menuItem: IMenuItem, event?: SyntheticEvent) => {
      dispatch(setDefaultLocation({ ...extraData.item }))
      dispatch(
        setActiveMenuItem({
          id: extraData.item.name,
          active: true,
        })
      )
      // sessionStorage.setItem('defaultLocation', extraData.item.id)
      localStorage.setItem('defaultLocation', extraData.item.id)
    }
  }

  const defaultLocationHelper = (locations: Array<Location>) => {
    //get DefaultLocationId set in session storage
    // const defaultLocationId =
    //       parseInt(sessionStorage.getItem('defaultLocation') as string  )
    const defaultLocationId = localStorage.getItem('defaultLocation')
      ? parseInt(localStorage.getItem('defaultLocation') as string)
      : -1
    // check if the default location is saved
    if (defaultLocationId) {
      const defaultLocation = locations?.filter(
        (loc: Location) => loc.id == defaultLocationId
      )[0]

      if (defaultLocation) {
        return defaultLocation
      }
    }
    // sessionStorage.setItem('defaultLocation', locations[0].id as unknown as string)
    if (locations.length > 0) {
      localStorage.setItem(
        'defaultLocation',
        locations[0].id as unknown as string
      )
    } else {
      localStorage.setItem('defaultLocation', 'NA')
    }
    // localStorage.setItem('defaultLocation', locations[0].id as unknown as string)
    return locations[0]
  }

  const callNumLocationAction = (locations: Array<Location>) => {
    setNumLocations(locations.length)
    dispatch(
      prependMenuItem({
        item: locations.slice(0, 3).map((loc: Location, index) => ({
          id: 'locations-' + loc.name,
          label: loc.name,
          link: '',
          icon: marker,
          active: getDefaultLocation?.name === loc.name,
          specialComponent: (
            <DeleteLocation id={loc.id} menuIdString={loc.name} />
          ),
          callback: menuCallback({
            item: locations[index],
          }), // custom callback for menu item
          multiSelect: false,
        })),
        parentId: 'location-menu',
      })
    )
  }
  //
  useEffect(() => {
    ;(async () => {
      if (isAuthenticated) {
        const response = await dispatch(fetchUser())
        datadogRum.setUser({
          id: response.payload.id,
          name: response.payload.firstname + ' ' + response.payload.lastname,
          email: response.payload.email,
        })
        if (!response.payload.isActive || !response.payload.isVerified) {
          errorStatus.addMessage(
            'You need to verify your email before using the workbook. Check your email for a link.',
            400,
            'error',
            true,
            1000000
          )
          dispatch(disableAllMenuItems())
          return
        }
        if (
          response.payload.reseller &&
          response.payload.reseller.reseller === AEROX_ORG_ID
        ) {
          dispatch(
            prependMenuItem({
              item: {
                id: 'aerox-menu',
                label: 'Active Drone Ops',
                link: '#',
                icon: marker,
                active: false,
                multiSelect: false,
                disabled: false,
                subMenu: [],
              },
              parentId: 'side-menu-root',
            })
          )
        }
        const locations: Array<Location> = response?.payload?.locations
        const defaultLocation: Location = defaultLocationHelper(locations)
        callNumLocationAction(locations)
        dispatch(selectedItems(defaultLocation?.name))
        dispatch(selectedItemIds('locations-' + defaultLocation?.name))
        dispatch(setDefaultLocation(defaultLocation))
        const savedProfiles = await fetchSavedProfiles()
        dispatch(setSavedProfile(savedProfiles))
        // dispatch(setSavedProfile())
        dispatch(
          setActiveMenuItem({
            id: defaultLocation?.name,
            active: true,
          })
        )
      }
    })()
  }, [isAuthenticated])

  //event listener to listen to localstorage logout event
  //this logs the user out of all active sessions from all tabs

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout-event') {
        dispatch(userLogoutAction())
        dispatch(initSideMenu())
        navigate('/')
      } else if (event.key === 'login-event') {
        navigate('/map')
      }
    })
  }, [])

  useEffect(() => {
    const currentPath = location.pathname
    const isMapActive = currentPath === '/map' || currentPath === '/'
    setMapActive(isMapActive)
  }, [location.pathname])

  // if (token === null || token === undefined || token === '') {
  //   /**
  //    * if token is null or undefined or token is empty, redirect to login page
  //    */
  //   return (
  //     <Routes>
  //       <Route
  //         path="/"
  //         element={
  //           <div className="flex flex-col bg-white w-full h-screen max-h-screen">
  //             <LoginPage />
  //           </div>
  //         }
  //       />
  //       <Route path="/session/:id/:token" element={<SessionRedirect />} />
  //       <Route
  //         path="/login"
  //         element={
  //           <div className="flex flex-col bg-white w-full h-screen max-h-screen">
  //             <LoginPage />
  //           </div>
  //         }
  //       />
  //       {/**
  //        * we also allow the user to register if they are not logged in
  //        */}
  //       <Route
  //         path="/signup"
  //         element={
  //           <div className="flex flex-col bg-white w-full h-screen max-h-screen">
  //             <RegisterPage />
  //           </div>
  //         }
  //       />
  //       {/**
  //        * and allow the user to recover their lost password if they are not logged in
  //        */}
  //       <Route
  //         path="/resetPassword"
  //         element={
  //           <div className="flex flex-col bg-white w-full h-screen max-h-screen">
  //             <ForgotPasswordPage />
  //           </div>
  //         }
  //       />
  //       {/**
  //        * But any other route will redirect to the login page
  //        */}
  //       <Route
  //         path="*"
  //         element={
  //           <div className="flex flex-col bg-white w-full h-screen max-h-screen ">
  //             <LoginPage />
  //           </div>
  //         }
  //       />
  //     </Routes>
  //   )
  // } else {
  //   return (
  //     <Provider store={store}>
  //       <SlideOverDialogProvider>
  //         <TWSContext.Provider value={{ twsElements, setTwsElements }}>
  //           <div className="flex flex-col bg-white w-full h-screen max-h-screen overflow-y-clip">
  //             <TopNavigation burgerHandler={sideNavCallback}></TopNavigation>
  //             <Notifier position={'top-right'} />
  //             <div className="relative flex flex-row h-[calc(100vh-4rem)] basis-auto flex-grow shrink bg-white">
  //               <SideNavigation setMessage={setMessage}></SideNavigation>
  //               <MenuSelection />
  //               <div className="z-[99999998]">
  //                 {selectedItemsx.includes('Surface Observations') &&
  //                   mapActive && (
  //                     <SlideOverDialog
  //                       selectedSideNav={'Surface Observations'}
  //                     />
  //                   )}
  //               </div>
  //               <div
  //                 className={`flex flex-col flex-grow z-1 ${
  //                   window.location.pathname === '/' ||
  //                   window.location.pathname === '/map'
  //                     ? ''
  //                     : 'hidden'
  //                 }`}
  //               >
  //                 <MapComponent selectedSideNav={selectedSideNav} />
  //               </div>
  //               <Routes>
  //                 {/*<Route*/}
  //                 {/*  path="/"*/}
  //                 {/*  element={*/}

  //                 {/*  }*/}
  //                 {/*/>*/}
  //                 <Route path="/login" element={<LoginPage />} />
  //                 <Route path="/signup" element={<RegisterPage />} />
  //                 <Route
  //                   path="/resetPassword"
  //                   element={<ForgotPasswordPage />}
  //                 />
  //                 {/* To put a component into the main section of the page, just wrap it in the MainContainer or make sure your
  //                 component fits into the layout.
  //                 **Use <Link to=...> for your internal links. */}
  //                 {/*<Route*/}
  //                 {/*  path="/map"*/}
  //                 {/*  element={*/}
  //                 {/*    <div className="flex flex-col flex-grow">*/}
  //                 {/*      <MapComponent selectedSideNav={selectedSideNav} />*/}
  //                 {/*    </div>*/}
  //                 {/*  }*/}
  //                 {/*/>*/}
  //                 <Route
  //                   path="/session/:id/:token"
  //                   element={<SessionRedirect />}
  //                 />
  //                 <Route path="/userprofile" element={<UserProfile />} />
  //                 <Route path="/sensordetails" element={<SensorDetails />} />
  //                 <Route
  //                   path="/customthresholds"
  //                   element={<CustomThresholds />}
  //                 />
  //                 <Route path="/sensordetails" element={<SensorDetails />} />
  //                 <Route
  //                   path="/customthresholds"
  //                   element={<CustomThresholds />}
  //                 />
  //                 <Route path="/faq" element={<FAQPage />} />
  //                 <Route path="*" element={null} />
  //               </Routes>
  //             </div>
  //           </div>
  //         </TWSContext.Provider>
  //       </SlideOverDialogProvider>
  //     </Provider>
  //   )
  // }


  return (
    <RequireAuth>
      <Provider store={store}>
        <SlideOverDialogProvider>
          <TWSContext.Provider value={{ twsElements, setTwsElements }}>
            <div className="flex flex-col bg-white w-full h-screen max-h-screen overflow-y-clip">
              <TopNavigation burgerHandler={sideNavCallback}></TopNavigation>
              <Notifier position={'top-right'} />
              <div className="relative flex flex-row h-[calc(100vh-4rem)] basis-auto flex-grow shrink bg-white">
                <SideNavigation setMessage={setMessage}></SideNavigation>
                <MenuSelection />
                <div className="z-[99999998]">
                  {selectedItemsx.includes('Surface Observations') &&
                    mapActive && (
                      <SlideOverDialog
                        selectedSideNav={'Surface Observations'}
                      />
                    )}
                </div>
                <div
                  className={`flex flex-col flex-grow z-1 ${
                    window.location.pathname === '/' ||
                    window.location.pathname === '/map'
                      ? ''
                      : 'hidden'
                  }`}
                >
                  <MapComponent selectedSideNav={selectedSideNav} />
                </div>
                <Routes>
                  {/*<Route*/}
                  {/*  path="/"*/}
                  {/*  element={*/}

                  {/*  }*/}
                  {/*/>*/}
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<RegisterPage />} />
                  <Route
                    path="/resetPassword"
                    element={<ForgotPasswordPage />}
                  />
                  {/* To put a component into the main section of the page, just wrap it in the MainContainer or make sure your
                  component fits into the layout.
                  **Use <Link to=...> for your internal links. */}
                  {/*<Route*/}
                  {/*  path="/map"*/}
                  {/*  element={*/}
                  {/*    <div className="flex flex-col flex-grow">*/}
                  {/*      <MapComponent selectedSideNav={selectedSideNav} />*/}
                  {/*    </div>*/}
                  {/*  }*/}
                  {/*/>*/}
                  <Route
                    path="/session/:id/:token"
                    element={<SessionRedirect />}
                  />
                  <Route path="/userprofile" element={<UserProfile />} />
                  <Route path="/sensordetails" element={<SensorDetails />} />
                  <Route
                    path="/customthresholds"
                    element={<CustomThresholds />}
                  />
                  <Route path="/sensordetails" element={<SensorDetails />} />
                  <Route
                    path="/customthresholds"
                    element={<CustomThresholds />}
                  />
                  <Route path="/faq" element={<FAQPage />} />
                  <Route path="*" element={null} />
                </Routes>
              </div>
            </div>
          </TWSContext.Provider>
        </SlideOverDialogProvider>
      </Provider>
    </RequireAuth>
    ) 
}

export { DeleteLocation }
